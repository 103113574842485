<template>
  <v-container class="col-11 col-sm-7 col-md-5 col-lg-4 col-xl-3 mt-10">
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'LoginLayout'
}
</script>
